import React from 'react';
import { Typography } from '@material-ui/core';

const ShareText = ({ children, align = 'inherit' }) => (
    <Typography display="inline" align={align} variant="h6" component="p">
        {children}
    </Typography>
);

export default ShareText;
