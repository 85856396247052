import React from 'react';
import { graphql } from 'gatsby';
import Theme from '../components/PageCommon/Theming/ThemeProvider';
import PageContainer from '../components/PageCommon/PageContainer/PageContainer';
import PageMeta from '../components/PageCommon/PageMeta/PageMeta';
import Footer from '../components/PageCommon/Footer/Footer';
import MainContainer from '../components/PageCommon/MainContainer/MainContainer';
import NavBar from '../components/PageCommon/NavBar/NavBar';
import { Grid } from '@material-ui/core';
import PostCard from '../components/Cards/PostCard/PostCard';

import useSiteMetadata from '../hooks/useSiteMetadata';

const BlogPostTemplate = ({ data }) => {
    const {
        html,
        excerpt_short,
        frontmatter: {
            date,
            title,
            category,
            themes,
            heroImage: {
                childImageSharp: { fluid },
            },
        },
        fields: { slug },
    } = data.markdownRemark;

    const { siteUrl } = useSiteMetadata();
    const heroImgUrl = siteUrl + fluid.src;
    const url = siteUrl + slug;

    return (
        <Theme>
            <PageMeta title={title} heroImgUrl={heroImgUrl} pageDescription={excerpt_short} url={url} />
            <PageContainer>
                <NavBar page={category} />
                <MainContainer>
                    <Grid container spacing={1}>
                        <Grid item style={{ width: '100%' }}>
                            <PostCard
                                title={title}
                                text={html}
                                date={date}
                                img={fluid}
                                siteUrl={siteUrl}
                                slug={slug}
                                themes={themes}
                            />
                        </Grid>
                    </Grid>
                </MainContainer>
                <Footer />
            </PageContainer>
            {/* Customized pinterest init script */}
            {/* p.setAttribute('data-pin-hover', 'true');
                    p.setAttribute('data-pin-round', 'true');
                    p.setAttribute('data-pin-tall', 'true'); */}
            {/* <script type="text/javascript">
                { (function (d) {
                    var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');
                    p.type = 'text/javascript';
                    p.async = true;
                    p.src = '//assets.pinterest.com/js/pinit.js';
                    f.parentNode.insertBefore(p, f);
                }(document)) };
            </script> */}
            <script type="text/javascript" async={true} defer={true} src="//assets.pinterest.com/js/pinit.js" />
        </Theme>
    );
};

export default BlogPostTemplate;

export const queryData = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            excerpt_short: excerpt(pruneLength: 150)
            frontmatter {
                date(formatString: "DD MMM, YYYY")
                title
                category
                themes
                heroImage {
                    childImageSharp {
                        ...lgImageFluid
                    }
                }
            }
            fields {
                slug
            }
        }
    }
`;
