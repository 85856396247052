import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import React from 'react';

const textColor = (props) => props?.theme?.ThemeLabel?.color ?? 'red';
const font = (props) => props?.theme?.ThemeLabel?.fontFamily ?? 'inherit';
const fontSize = (props) => props?.theme?.ThemeLabel?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.ThemeLabel?.fontWeight ?? 'inherit';
const hoverColor = (props) => props?.theme?.ThemeLabel?.hover?.color ?? 'inherit';

const StyledT = styled.span`
    margin-right: 1rem;
    margin-bottom: ${(props) => props.bottomMargin};
    display: inline-block;
`;

export const StyledTypography = ({ children, bottomMargin = '1rem', variant = 'h6' }) => {
    return (
        <StyledT bottomMargin={bottomMargin}>
            <Typography variant={variant}>{children}</Typography>
        </StyledT>
    );
};

const StyledA = styled.a`
    color: ${textColor};
    font-family: ${font};
    font-size: ${fontSize};
    font-weight: ${fontWeight};

    text-decoration: none;
    cursor: pointer;
    &:hover {
        color: ${hoverColor};
        text-decoration: none;
    }
`;

export const StyledLink = ({ children, href = null }) => {
    return <StyledA href={href}>{children}</StyledA>;
};
