import HeroImgStyles from './HeroImgStyles';
import React from 'react';

import useResponsiveSizing from '../../../hooks/useResponsiveSizing';

const HeroImg = ({ split, ...props }) => {
    const imgHeights = [
        // ['60vh', '100%'],
        ['465px', '465px'],
        ['65vh', '65vh'],
        ['65vh', '500px'],
        ['500px', '65vh'],
        ['500px', '65vh'],
    ];
    const splitImgHeights = [
        ['465px', '465px'],
        ['65vh', '65vh'],
        ['500px', '500px'],
        ['500px', '35vh'],
        ['500px', '35vh'],
    ];
    const matchedHeights = split ? splitImgHeights : imgHeights;

    return <HeroImgStyles imgHeights={useResponsiveSizing(matchedHeights)} {...props} />;
};

export default HeroImg;
