import styled from 'styled-components';

const getSize = (emSize) => emSize.substring(0, emSize.length - 2);

const h1TextColor = (props) => props?.theme?.DefaultCSS?.h1?.color ?? 'red';
const h1Font = (props) => props?.theme?.DefaultCSS?.h1?.fontFamily ?? 'inherit';
const h1FontSize = (props) => {
    const size = props?.theme?.DefaultCSS?.h1?.fontScale ?? '1em';
    return getSize(size);
};
const h1FontWeight = (props) => props?.theme?.DefaultCSS?.h1?.fontWeight ?? 'inherit';

const h2TextColor = (props) => props?.theme?.DefaultCSS?.h2?.color ?? 'red';
const h2Font = (props) => props?.theme?.DefaultCSS?.h2?.fontFamily ?? 'inherit';
const h2FontSize = (props) => {
    const size = props?.theme?.DefaultCSS?.h2?.fontScale ?? '1em';
    return getSize(size);
};
const h2FontWeight = (props) => props?.theme?.DefaultCSS?.h2?.fontWeight ?? 'inherit';

const h3TextColor = (props) => props?.theme?.DefaultCSS?.h3?.color ?? 'red';
const h3Font = (props) => props?.theme?.DefaultCSS?.h3?.fontFamily ?? 'inherit';
const h3FontSize = (props) => {
    const size = props?.theme?.DefaultCSS?.h3?.fontScale ?? '1em';
    return getSize(size);
};
const h3FontWeight = (props) => props?.theme?.DefaultCSS?.h3?.fontWeight ?? 'inherit';

const aTextColor = (props) => props?.theme?.DefaultCSS?.a?.color ?? 'red';
const aFont = (props) => props?.theme?.DefaultCSS?.a?.fontFamily ?? 'inherit';
const aFontSize = (props) => props?.theme?.DefaultCSS?.a?.fontScale ?? '1em';
const aHoverColor = (props) => props?.theme?.DefaultCSS?.a?.hover?.color ?? 'yellow';

const pTextColor = (props) => props?.theme?.DefaultCSS?.p?.color ?? 'red';
const pFont = (props) => props?.theme?.DefaultCSS?.p?.fontFamily ?? 'inherit';
const pFontSize = (props) => {
    const size = props?.theme?.DefaultCSS?.p?.fontScale ?? '1em';
    return getSize(size);
};
const pFontWeight = (props) => props?.theme?.DefaultCSS?.p?.fontWeight ?? 'inherit';

const DefaultCSSStyles = styled.div`
    white-space: pre-wrap;
    h1 {
        line-height: 1.167;
        letter-spacing: -0.01562em;
        color: ${h1TextColor};
        font-family: ${h1Font};
        font-size: calc(6rem * ${h1FontSize});
        font-weight: ${h1FontWeight};
        white-space: pre-wrap;
    }
    h2 {
        line-height: 1.6;
        letter-spacing: 0.0075em;
        color: ${h2TextColor};
        font-family: ${h2Font};
        font-size: calc(1.25rem * ${h2FontSize});
        font-weight: ${h2FontWeight};
        white-space: pre-wrap;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    h3 {
        line-height: 1.167;
        letter-spacing: 0em;
        color: ${h3TextColor};
        font-family: ${h3Font};
        font-size: calc(1.5rem * ${h3FontSize});
        font-weight: ${h3FontWeight};
        white-space: pre-wrap;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    ul,
    ol {
        line-height: 1.5;
        color: ${pTextColor};
        font-family: ${pFont};
        font-size: calc(1rem * ${pFontSize});
        font-weight: ${pFontWeight};
        white-space: pre-wrap;
        margin-top: -1.2rem;
        margin-bottom: -1.2rem;
    }
    li {
        line-height: 1.5;
        color: ${pTextColor};
        font-family: ${pFont};
        font-size: calc(1rem * ${pFontSize});
        font-weight: ${pFontWeight};
        white-space: pre-wrap;
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
    a {
        color: ${aTextColor};
        font-family: ${aFont};
        font-size: ${aFontSize};
        white-space: pre-wrap;
        text-decoration: none;
        cursor: pointer;

        &:hover {
            color: ${aHoverColor};
            text-decoration: none;
        }
    }
    p {
        line-height: 1.5;
        color: ${pTextColor};
        font-family: ${pFont};
        font-size: calc(1rem * ${pFontSize});
        font-weight: ${pFontWeight};
        white-space: pre-wrap;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
    img,
    picture {
        object-fit: cover;
        object-position: center;
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
`;

export default DefaultCSSStyles;
