import styled from 'styled-components';
import Img from 'gatsby-image';
import React from 'react';

const StyledImg = styled(Img)`
    min-height: ${(props) => props.minHeight};
    max-height: ${(props) => props.maxHeight};
`;

const HeroImgStyles = ({ imgHeights = ['65vh, 65vh'], ...props }) => {
    return <StyledImg {...props} minHeight={imgHeights[0]} maxHeight={imgHeights[1]} />;
};

export default HeroImgStyles;
