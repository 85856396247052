import SocialIcon from './SocialIcon';
import SharePanelStyles from './SharePanelStyles';
import ShareText from './ShareText';

import React from 'react';

import useSiteMetadata from '../../../hooks/useSiteMetadata';

const fbShareDialog = (url, fb_app_id) => {
    window.FB.ui(
        {
            appId: fb_app_id,
            app_id: fb_app_id,
            display: 'popup',
            method: 'share',
            href: url,
        },
        function(response) {
            if (response && !response.error_message) {
                console.log('FB: Posting completed.');
            } else {
                console.log('FB: Error while posting.');
            }
        },
    );
};

const SharePanel = ({ siteUrl, slug }) => {
    const piHref = 'https://www.pinterest.com/pin/create/button/';
    const twHref = 'https://twitter.com/intent/tweet?url=' + siteUrl + slug;
    const { fb_app_id } = useSiteMetadata();

    return (
        <SharePanelStyles>
            <ShareText>Dalies:</ShareText>
            <SocialIcon brand="fb" onClick={() => fbShareDialog(siteUrl + slug, fb_app_id)} />
            <SocialIcon brand="pi" href={piHref} dataPinCustom="true" dataPinDo="buttonBookmark" />
            <SocialIcon brand="tw" href={twHref} />
        </SharePanelStyles>
    );
};

export default SharePanel;
