import styled from 'styled-components';

const textColor = (props) => props?.theme?.SocialPanel?.color ?? 'inherit';
const fontSize = (props) => props?.theme?.SocialPanel?.fontScale ?? '1em';
const fontWeight = (props) => props?.theme?.SocialPanel?.fontWeight ?? 'inherit';

const SharePanelStyles = styled.div`
    color: ${textColor};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    width: 100%;
    height: 100%;
    white-space: nowrap;
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    justify-content: left;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
`;

export default SharePanelStyles;
