import React from 'react';

import PostCardStyles from './PostCardStyles';
import HeroImg from '../HeroCard/HeroImg';
import BlogTitle from '../Common/BlogTitle';
import CardTextContainer from '../Common/CardTextContainer';
import PostDate from '../Common/PostDate';
import SharePanel from '../Common/SharePanel';
import MarkdownText from '../Common/MarkdownText';
import DefaultCSS from '../Common/DefaultCSS';
import ThemeLabel from '../Common/ThemeLabel';

import { Grid } from '@material-ui/core';

const PostCard = (props) => {
    const title = props.title ?? '';
    const text = props.text ?? '';
    const date = props.date ?? '';
    const img = props.img ?? '';
    const siteUrl = props.siteUrl ?? '';
    const slug = props.slug ?? '';
    const themes = props.themes ?? [];

    const themeBaseUrl = '/themes/';

    return (
        <PostCardStyles>
            <Grid container>
                {/* important top set width otherwise gatsby-img will not work properly */}
                <Grid item style={{ width: '100%' }}>
                    <HeroImg alt="Illustrative image related to post" fluid={img} title={title} />
                </Grid>
                {/* this combo does not break ver centering and allows hor centering */}
                <Grid item style={{ width: '100%', display: 'flex' }}>
                    <CardTextContainer>
                        {themes.map((el, idx) => (
                            <ThemeLabel key={idx} href={themeBaseUrl + el}>
                                {el}
                            </ThemeLabel>
                        ))}
                        <BlogTitle gutterBottom={true}>{title}</BlogTitle>
                        <PostDate gutterBottom={true}>{date}</PostDate>
                        <SharePanel siteUrl={siteUrl} slug={slug} />
                        <DefaultCSS>
                            <MarkdownText text={text} />
                        </DefaultCSS>
                        <SharePanel siteUrl={siteUrl} slug={slug} />
                    </CardTextContainer>
                </Grid>
            </Grid>
        </PostCardStyles>
    );
};

export default PostCard;
