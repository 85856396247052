import React from 'react';
import { StyledTypography, StyledLink } from './ThemeLabelStyles';

const ThemeLabel = ({ children, align = 'inherit', href = null, bottomMargin = '1rem' }) => (
    <StyledTypography bottomMargin={bottomMargin} align={align} variant="body1" component="p">
        <StyledLink href={href}>{children.toUpperCase()}</StyledLink>
    </StyledTypography>
);

export default ThemeLabel;
